#main_configurator {
 touch-action: none;
 margin: 0;
 border: none;
 padding: 0;
 text-align: center;
 background-color: white;
 position: absolute;
 left: 0;
 top: 0;
 right: 0;
 bottom: 0;
 & iframe#godot_render{
   display: block;
   margin: 0;
   position: absolute;
   bottom: 0;
   left: 0;
   width: 66%;
   height: calc(100% - 140px);
   border: none;
   outline: none;
 }
 & div#configurator_content{
   touch-action: none;
   position: absolute;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   & div#breadcrumb-bar{
     display: flex;
     align-items: center;
     position: relative;
     width: 100%;
     height: 139px;
     border-bottom: 1px solid black;
     & img.scopema-logo{
       width: 207px;
       height: 51px;
     }
   }
   & div.main_row{
     position: absolute;
     left: 0;
     top: 140px;
     right: 0;
     bottom: 0;
     & #configurator {
       position: absolute;
       top:0;
       left:66%;
       height: 100%;
       width: calc(34% - 1px);
       overflow: auto;
       border-left: 1px solid black;
     }
   }
 }
}

@media only screen and (max-width: 1200px) {
 #main_configurator {
   & iframe#godot_render{
     position: absolute;
     top:80px;
     height: calc(50% - 40px);
     width: 100%;
   }
   div#configurator_content{
     & div#breadcrumb-bar {
       height: 79px;
       & img.scopema-logo {
         width: calc(207px / 2.3);
         height: calc(51px / 2.3);
         position: absolute;
         background-color: white;
         bottom: 0;
         left: 0;
         transform: translateY(calc(100% + 1px));
         z-index: 1000;
         padding: 7px 7px 7px 7px;
         border-radius: 0 0 25px 0;
         border: solid black;
         border-width: 0 1px 1px 0;
       }
     }
     div.main_row{
       top: 80px;
       & #configurator {
         position: absolute;
         top:50%;
         left:0;
         height: calc(50% - 1px);
         width: 100%;
         overflow: auto;
         border-left: none;
         border-top: 1px solid black;
       }
     }
   }
 }
}