#main_questions {
    & div#questions {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    & div#breadcrumb-bar{
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;
        width: 100%;
        height: 139px;
        border-bottom: 1px solid black;

        & form input[type=text] {
            color: #212529;
            background-color: #fff;
            border: 1px solid #ced4da;
            padding: 10px;
            width: 25vw;

            &:focus {
                color: #212529;
                background-color: #fff;
                border-color: #86b7fe;
                outline: 0;
                box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
            }
        }

        & img.scopema-logo{
            width: 207px;
            height: 51px;
        }
    }
}